<template>
    <v-navigation-drawer v-model="drawer" fixed temporary right width="600" max-width="80%" style="z-index: 999;">
        <v-list-item>
            <v-btn v-if="isMobile" icon large class="mr-1" @click="drawer = false"><v-icon>mdi-close</v-icon></v-btn>

            <v-list-item-avatar size="35" class="mr-2">
                <v-img v-if="contact?.profile_picture" :src="contact?.profile_picture"></v-img>
                <div v-else class="w-full h-full primary d-flex justify-center">
                    <v-icon color="white" style="font-size:35px">mdi-account</v-icon>
                </div>
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title>{{ contact.override_name || contact.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ contact.phone }}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
            <v-list-item v-if="contact?.tags?.length > 0" class="mb-2">
                <v-list-item-icon class="mb-2">
                    <v-icon>mdi-tag-multiple-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title class="mb-2 subtitle-2"><b>Tags</b></v-list-item-title>
                    <v-list-item-title class="d-flex flex-wrap">
                        <v-chip small class="mr-1 mt-1" v-for="(tag, index_tag) in contact?.tags" :color="tags_dictionary[tag]?.color" :key="`tag2_${index_tag}`" label>{{ tags_dictionary[tag]?.name || 'Tag apagada' }}</v-chip>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="contact_data_vars.length > 0" class="mb-2">
                <v-list-item-icon class="mb-2">
                    <v-icon>mdi-account-details-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title class="mb-2 subtitle-2"><b>Variáveis</b></v-list-item-title>
                    <v-list-item-title v-for="(item, index) in contact_data_vars" :key="`c_var_${index}`">
                        <b>{{ item.key }}:</b> {{ item.value }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item class="mb-2">
                <v-list-item-icon class="mb-2">
                    <v-icon>mdi-comment-text-multiple-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title class="mb-2 subtitle-2"><b>Anotações</b></v-list-item-title>
                    <v-row>
                        <v-col cols="12" v-for="(text, index) in contact.annotations" :key="`annotations_${index}`" class="d-flex">
                            <v-textarea class="mt-2" v-model="contact.annotations[index]" rows="4" :label="`Anotação ${index + 1}`" placeholder="Escreva aqui a anotação sobre esse contato ou atendimento efetuado." outlined hide-details="auto" />
                            <v-btn icon color="error lighten-2" class="ml-2" @click="contact.annotations.splice(index, 1)"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                        </v-col>

                        <v-col cols="12">
                            <v-btn small class="mr-4" color="primary" outlined @click="contact.annotations.push('')">Nova anotação</v-btn>
                            <v-btn small color="primary" @click="changeContactAnnotations()">Salvar</v-btn>
                        </v-col>
                    </v-row>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import Api from '@/lib/Api';
import { emitToastr } from "@/lib/Utils";

export default {
    props: {
        value: Boolean,
        contact_id: String,
        tags_dictionary: Object
    },
    data() {
        return {
            loading: false,
            contact: {},
            contact_data_vars: []
        }
    },
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown;
        },

        drawer: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    watch: {
        drawer: {
            async handler() {
                if (this.drawer && this.contact_id) {
                    this.loading = true;
                    const resp = await Api.getContactById(this.contact_id);
                    this.loading = false;
                    if (!resp.error && resp.message) {
                        this.contact = resp.message;
                        if (this.contact.data && Object.keys(this.contact.data).length > 0) {
                            this.contact_data_vars = Object.keys(this.contact.data).map(item => ({ key: item, value: this.contact.data[item] }));
                        }
                    }
                }
            },
            deep: false,
        }
    },
    methods: {
        async changeContactAnnotations() {
            this.loading = true;
            const resp = await Api.changeContactAnnotations(this.contact);
            this.loading = false;
            if (!resp.error) emitToastr("success", "Anotações atualizadas");

        }
    }
};
</script>

<style></style>