<template>
    <v-dialog v-model="dialog" width="1200" scrollable>
        <v-card>
            <v-card-title>Biblioteca de arquivos</v-card-title>
            <v-card-subtitle>
                Envie imagens, áudios, vídeos e documentos com facilidade através de sua biblioteca personalizada.
            </v-card-subtitle>

            <v-tabs v-model="tab" show-arrows @change="changeAllowedFileTypes">
                <v-tab v-for="item in tabs" :key="item.name">
                    {{ item.name }}
                </v-tab>
            </v-tabs>



            <v-card-text>
                <v-card outlined class="mt-2">
                    <v-card-title>Novo arquivo</v-card-title>
                    <v-card-subtitle>Adicione um novo arquivo aqui, envie direto ou salve na biblioteca para reutilizar</v-card-subtitle>
                    <v-card-text>
                        <v-row class="mt-1" v-if="tab == 1">
                            <v-btn-toggle mandatory v-model="audio_type" color="primary">
                                <v-btn small value="file">
                                    <v-icon size="18" class="mr-2">mdi-file</v-icon>
                                    <span>Upload de arquivo mp3</span>
                                </v-btn>
                                <v-btn small value="record">
                                    <v-icon size="18" class="mr-2">mdi-microphone</v-icon>
                                    <span>Gravação de áudio</span>
                                </v-btn>
                            </v-btn-toggle>
                        </v-row>

                        <v-row class="pb-2">
                            <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="d-flex" v-if="tab != 1 || (tab == 1 && audio_type == 'file')">
                                <v-file-input :accept="allowed_file_types" v-model="file" label="Adicionar novo arquivo" dense outlined hide-details="auto" @change="fileChanged">Novo arquivo</v-file-input>
                            </v-col>

                            <v-col cols="12" class="d-flex align-center" v-if="tab == 1 && audio_type == 'record'">
                                <v-btn @click="startRecording" v-if="!this.recording"><v-icon class="mr-1">mdi-microphone</v-icon>Gravar</v-btn>
                                <v-btn @click="stopRecording" v-if="this.recording"><v-icon class="mr-1">mdi-stop</v-icon>Parar</v-btn>

                                <audio class="ml-2" v-show="audio_source" :src="audio_source" controls></audio>
                                <v-text-field v-model="record_filename" class="mx-2" label="Nome do arquivo" outlined dense hide-details="auto" v-if="audio_source"></v-text-field>
                                <v-btn outlined color="primary" :loading="loading" @click="uploadRecord" v-if="audio_source">Salvar na biblioteca</v-btn>
                            </v-col>

                            <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="d-flex" v-if="file">
                                <v-text-field v-model="filename" class="mx-2" label="Nome do arquivo" outlined dense hide-details="auto"></v-text-field>
                                <v-btn outlined color="primary" :loading="loading" @click="uploadFile">Salvar na biblioteca</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-card outlined class="mt-2">
                    <v-card-title>Arquivo existente na biblioteca</v-card-title>
                    <v-card-subtitle>Selecione um arquivo previamente salvo</v-card-subtitle>
                    <v-card-text>
                        <v-tabs-items v-model="tab">
                            <v-tab-item v-for="item in tabs" :key="item.name">
                                <v-row class="py-2">
                                    <v-col class="cursor-pointer" cols="6" lg="2" md="3" sm="4" v-for="(m, index) in medias.filter(m => m.type == tabs[tab].type)" :key="index" @click="selectMedia(m)">
                                        <div :class="selectedMedia?._id == m._id ? 'selected pa-1' : 'pa-1'">

                                            <v-img v-if="m.type == 'image'" :src="m.url" cover class="media-image"></v-img>

                                            <v-icon size="60" v-if="m.type == 'audio'" class="media-text">mdi-microphone-message</v-icon>
                                            <v-icon size="60" v-if="m.type == 'video'" class="media-text">mdi-video-box</v-icon>
                                            <v-icon size="60" v-if="m.type == 'document'" class="media-text">mdi-file-document-outline</v-icon>

                                            <div class="media-text">{{ m.name }}</div>
                                            <div class="text-center mt-2" v-if="selectedMedia?._id == m._id">
                                                <v-btn outlined small color="error" @click="deleteMedia(m)"><v-icon>mdi-trash-can</v-icon> {{ can_delete ? 'Confirmar' : 'Apagar' }}</v-btn>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                </v-card>

            </v-card-text>

            <v-card-actions>
                <div v-if="tab == 1 && selectedMedia?.type == 'audio'">
                    <v-switch dense class="pl-2" v-model="selectedMedia.ptt" inset label="Enviar como gravado agora"></v-switch>
                </div>
            </v-card-actions>

            <v-card-actions>

                <v-spacer></v-spacer>
                <v-btn color="error" text @click="closeDialog">Cancelar</v-btn>
                <v-btn color="success" text :disabled="!selectedMedia._id" :loading="loading" @click="send">Enviar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Api from '@/lib/Api';
import { emitToastr } from '@/lib/Utils';
const MicRecorder = require('mic-recorder-to-mp3');
const recorder = new MicRecorder({ bitRate: 128 });

export default {
    props: {
        value: Boolean,
        medias: Array,
        updateMedias: Function,
        sendMedia: Function,
    },
    data() {
        return {
            audio_source: null,
            audio_type: 'file',
            tab: 0,
            selectedMedia: {},
            tabs: [
                { name: 'Imagens', type: 'image' },
                { name: 'Áudios', type: 'audio' },
                { name: 'Vídeos', type: 'video' },
                { name: 'Documentos', type: 'document' },
            ],
            allowed_file_types_base: [
                "image/jpeg, image/png",
                ".mp3", //DESCOBRIR QUAL NÃO DÁ CERTO. M4A DÁ BUG SILENCIOSO BEM RUIM "audio/aac , audio/mp4 , audio/mpeg , audio/amr , audio/ogg",
                "video/mp4, video/3gp",
                "text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ],
            allowed_file_types: "image/jpeg, image/png",
            loading: false,
            file: null,
            type: 'image',
            filename: '',
            record_filename: '',
            audio_data: null,
            recording: false,
            can_delete: false
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(val) {
                if (!val) {
                    if (this.recording) this.stopRecording();
                    this.can_delete = false;
                }
                this.$emit('input', val);
            }
        }
    },

    watch: {
        tab: {
            handler() {
                this.selectedMedia = {};
                this.file = null;
                if (this.recording) this.stopRecording();
                this.can_delete = false;
            },
            deep: true,
        }
    },

    methods: {
        clear() {
            this.loading = false;
            this.file = null;
            this.tab = 0;
            this.allowed_file_types = this.allowed_file_types_base[0];
            this.selectedMedia = {};
            this.filename = ''
            this.type = 'image';
        },

        changeAllowedFileTypes() {
            this.allowed_file_types = this.allowed_file_types_base[this.tab];
        },

        fileChanged(data, is_recording) {
            this.selectedMedia = { _id: 1, file: data, is_recording };
        },

        closeDialog() {
            this.clear();
            this.dialog = false;
        },

        selectMedia(media) {
            if (media?._id == this.selectedMedia?._id) return;
            this.can_delete = false;
            this.selectedMedia = media;
            this.$forceUpdate();
        },

        async send() {
            if (this.selectedMedia._id == 1) {
                //ARQUIVO NOVO
                if (!this.selectedMedia.is_recording) await this.uploadTempFile();
                if (this.selectedMedia.is_recording) await this.uploadRecord(true);
            }
            else {
                //ARQUIVO DA BIBLIOTECA
                this.sendMedia(this.selectedMedia);
                this.clear();
            }

        },

        async uploadFile() {
            if (!this.file) return emitToastr("error", "É necessário selecionar um arquivo");
            if (!this.filename) return emitToastr("error", "É necessário dar um nome para o arquivo");

            this.loading = true;
            const resp = await Api.uploadFile(this.file, this.tabs[this.tab].type, this.filename, false);
            this.loading = false;

            if (!resp.error && resp.message) {
                emitToastr("success", "Upload feito com sucesso!");
                this.updateMedias(resp.message)
                this.file = null;
                this.filename = '';
            }
        },

        async uploadTempFile() {
            if (!this.file) return emitToastr("error", "É necessário selecionar um arquivo");

            this.loading = true;
            const resp = await Api.uploadFile(this.file, this.tabs[this.tab].type, "", true);
            this.loading = false;

            if (!resp.error && resp.message) {
                this.sendMedia(resp.message);
                this.clear();
            }
        },

        async uploadRecord(temp = false) {
            if (!this.audio_data) return emitToastr("error", "É necessário gravar um áudio");
            if (!temp && !this.record_filename) return emitToastr("error", "É necessário dar um nome para o arquivo");

            this.loading = true;
            const resp = await Api.uploadFile(this.audio_data, 'audio', temp ? "" : this.record_filename, temp);
            this.loading = false;

            if (!resp.error && resp.message) {
                if (temp) {
                    const media = { ...resp.message, ptt: true };
                    this.sendMedia(media);
                    this.clear();
                }
                else {
                    emitToastr("success", "Upload feito com sucesso!");
                    this.updateMedias(resp.message)
                }

                this.audio_source = null;
                this.audio_data = null;
                this.record_filename = '';
            }
        },


        async startRecording() {
            recorder.start().then(() => {
                this.recording = true;
            }).catch(() => {
                this.recording = false;
            });
        },

        stopRecording() {
            recorder
                .stop()
                .getMp3().then(([buffer]) => {
                    const file = new File(buffer, 'gravacao.mp3', {
                        type: "audio/mpeg",
                        lastModified: Date.now()
                    });

                    this.audio_data = file;
                    this.audio_source = URL.createObjectURL(file);
                    this.recording = false;
                    this.fileChanged(file, true);

                }).catch(() => {
                    this.recording = false;
                });
        },

        async deleteMedia(item) {
            if (this.can_delete) {
                const resp = await Api.deleteMedia(item);
                if (!resp.error && resp.message) {
                    emitToastr("success", "Arquivo apagado com sucesso!");
                    this.updateMedias({ type: 'delete', media_id: item._id.toString() });
                }
            }
            else {
                this.can_delete = true;
            }
        }
    }
};
</script>

<style>
.media-image {
    border-radius: 4px;
}

.media-image:hover {
    opacity: 0.5;
}

.media-text {
    width: 100%;
    text-align: center;
}

.selected {
    border-radius: 4px;
    border: 3px solid #00e31e91;
}
</style>