<template>
    <div class="menu-page">
        <div>
            <v-card class="mb-2">
                <v-card-title class="pb-0">
                    Filtros
                </v-card-title>

                <v-card-text class="py-0">
                    <v-list dense>
                        <v-list-item class="mb-0">
                            <span class="filter-menu-title">Tipo de atendimento</span>
                            <v-btn-toggle multiple mandatory v-model="pre_filter.types" dense color="primary">
                                <v-btn small v-for="(item, index) in types" :value="item" :key="index">
                                    <v-icon size="18" class="mr-2">{{ item.icon }}</v-icon>
                                    <span>{{ item.name }}</span>
                                </v-btn>
                            </v-btn-toggle>
                        </v-list-item>

                        <v-list-item class="mb-0">
                            <span class="filter-menu-title">Departamento</span>
                            <v-btn-toggle borderless dense v-model="pre_filter.departments" background-color="transparent" multiple color="primary" class="d-flex flex-wrap">
                                <v-btn small bordered :value="item" v-for="(item, index) in user_role_departments" :key="index" class="mb-1" style="border: 1px solid grey">
                                    <span>{{ item.name }}</span>
                                </v-btn>
                            </v-btn-toggle>
                        </v-list-item>

                        <v-list-item class="mb-0">
                            <span class="filter-menu-title">Tags</span>
                            <v-btn-toggle borderless dense v-model="pre_filter.tags" multiple background-color="transparent" color="primary" class="d-flex flex-wrap">
                                <v-btn small bordered :value="item" v-for="(item, index) in tags" :key="index" class="mb-1" style="border: 1px solid grey">
                                    <span>{{ item.name }}</span>
                                </v-btn>
                            </v-btn-toggle>
                        </v-list-item>

                        <v-list-item class="mb-2">
                            <span class="filter-menu-title">Buscar</span>
                            <v-text-field dense v-model="pre_filter.text" outlined label="Nome ou telefone" hide-details="auto" style="max-width: 400px;"></v-text-field>
                        </v-list-item>

                        <v-list-item>
                            <div class="filter-item my-3" style="display: flex; width: 100%;">
                                <v-spacer></v-spacer>
                                <v-btn outlined color="error" class="mr-4" dense @click="clearFilters">Limpar filtros</v-btn>
                                <v-btn outlined color="primary" dense @click="applyFilters">Aplicar filtros</v-btn>
                            </div>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>

            <v-card>
                <v-data-table :headers="headers" :items="objects" :options.sync="options" :server-items-length="totalObjects" :loading="loading">

                    <template v-slot:[`item.created`]="{ item }">
                        {{ $date.defaultFormat(item.created) }}
                    </template>

                    <template v-slot:[`item.finished`]="{ item }">
                        {{ item.finished ? $date.defaultFormat(item.finished) : 'Em andamento' }}
                    </template>

                    <template v-slot:[`item.chatbot`]="{ item }">
                        {{ `${item.chip?.name || 'Canal apagado'} - ${item.chatbot?.name || 'Chatbot apagado'}` }}
                    </template>

                    <template v-slot:[`item.contact.tags`]="{ item }">
                        <v-chip small class="mr-1" v-for="(tag, index_tag) in item.contact?.tags" :color="tags_dictionary[tag]?.color" :key="`tag_${index_tag}`" label>{{ tags_dictionary[tag]?.name || 'Tag apagada' }}</v-chip>
                    </template>


                    <template v-slot:[`item.contact.name`]="{ item }">
                        {{ item.contact?.override_name || item.contact?.name }}
                    </template>

                    <template v-slot:[`item.department`]="{ item }">
                        <v-chip small v-if="item.department" label><v-icon small left>mdi-briefcase-outline</v-icon>{{ item.department?.name || 'Departamento apagado' }}</v-chip>
                    </template>

                    <template v-slot:[`item.contact.profile_picture`]="{ item }">
                        <v-avatar size="60" class="my-2">
                            <v-img :src="item.contact?.profile_picture" v-if="item.contact?.profile_picture" />
                            <div v-else class="w-full h-full primary d-flex justify-center">
                                <v-icon color="white" style="font-size:60px">mdi-account</v-icon>
                            </div>
                        </v-avatar>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="d-flex">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="mr-2" medium color="primary" v-bind="attrs" v-on="on" @click="transferDepartment(item)">mdi-monitor-account</v-icon>
                                </template>
                                <span>Enviar para atendimento humano</span>
                            </v-tooltip>

                            <v-tooltip top :key="item._id">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon medium color="primary" v-bind="attrs" v-on="on" @click="getMessages(item)">mdi-eye</v-icon>
                                </template>
                                <span>Ver conversa</span>
                            </v-tooltip>
                        </div>
                    </template>
                </v-data-table>
            </v-card>
        </div>

        <v-dialog v-model="dialogConversation" scrollable max-width="760">
            <v-card>
                <v-card-title :class="selectedConversation?.is_manual ? 'warning' : 'success'">
                    <div class="contact-container">
                        <v-avatar size="60" class="my-2">
                            <v-img :src="selectedConversation?.contact?.profile_picture" v-if="selectedConversation?.contact?.profile_picture" />
                            <div v-else class="w-full h-full primary d-flex justify-center">
                                <v-icon color="white" style="font-size:60px">mdi-account</v-icon>
                            </div>
                        </v-avatar>
                        <div class="contact-info">
                            <span class="contact-name">{{ selectedConversation?.contact?.override_name || selectedConversation?.contact?.name || "Contato" }} <small v-if="selectedConversation?.is_manual">(Atendimento humano)</small></span>
                            <span class="contact-phone">{{ selectedConversation?.contact?.phone }}</span>
                        </div>

                        <v-spacer></v-spacer>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn large icon color="black" @click="transferDepartment(selectedConversation)"><v-icon v-bind="attrs" v-on="on">mdi-monitor-account</v-icon></v-btn>
                            </template>
                            <span>Enviar para atendimento humano</span>
                        </v-tooltip>
                    </div>

                </v-card-title>

                <v-card-text ref="dialogRef" :style="{ backgroundImage: 'url(' + require('@/assets/whatsapp-background.png') + ')', backgroundRepeat: 'repeat' }">
                    <div class="messages-container">
                        <div v-for="(item, message_index) in messages" :class="`bubble chat-item ${item.origin == 'customer' ? 'customer' : 'chatbot'}-message`" :key="message_index">
                            <audio controls class="mb-2" v-if="item.type == 'audio' && item.media_url">
                                <source :src="item.media_url" type="audio/mpeg">
                                Seu navegador não suporte player de audio.
                            </audio>
                            <span v-if="item.type == 'audio'">[TRANSCRIÇÃO]: </span>

                            <div v-if="item.type == 'image'">
                                <v-img class="cursor-pointer" :src="item.media_url" contain height="auto" max-width="80vw" @click="showImage(item.media_url)"></v-img>
                            </div>

                            {{ item.message }}

                            <div class="message-time">
                                {{ $date.smallFormat(item.created) }}
                                <v-icon v-if="item.origin != 'customer'" size="13" :color="item.status >= 4 ? 'blue' : 'grey'">mdi-check-all</v-icon>
                            </div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="departmentDialog" max-width="600">
            <v-card>
                <v-card-title class="text-h5">
                    Classifique e transfira o atendimento
                </v-card-title>
                <v-card-subtitle v-if="selectedConversation?.contact?.name">Transferir atendimento de {{ selectedConversation.contact?.override_name || selectedConversation.contact?.name }}</v-card-subtitle>

                <v-card-text v-if="selectedConversation?.contact">
                    <v-select chips small-chips multiple outlined v-model="selectedConversation.contact.tags" :items="tags" item-text="name" item-value="_id" label="Tags ou rótulos" hide-details="auto" @change="changeTags = true">
                        <template #selection="{ item }">
                            <v-chip small :color="item.color">{{ item.name }}</v-chip>
                        </template>
                    </v-select>
                </v-card-text>

                <v-card-text v-if="selectedConversation">
                    <v-select chips small-chips outlined v-model="selectedConversation.department" :items="departments" item-text="name" item-value="_id" label="Departamento" hide-details="auto">
                        <template #selection="{ item }">
                            <v-chip small color="primary">{{ item.name }}</v-chip>
                        </template>
                    </v-select>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="departmentDialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="primary" text @click="departmentDialog = false; changeConversationData()">
                        Transferir
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay z-index="99999" opacity="0.9" v-model="imageViewerDialog">
            <v-img :src="media_url" contain style="max-height: 85vh;max-width:85vw"></v-img>
            <div style="text-align: center;">
                <v-btn outlined class="mt-2 mr-4" large color="error lighten-1" @click="imageViewerDialog = false">
                    <v-icon class="mr-2">mdi-close</v-icon>
                    Fechar
                </v-btn>
                <v-btn outlined class="mt-2" large color="primary" @click="$saveImage(media_url)">
                    <v-icon class="mr-2">mdi-content-save-outline</v-icon>
                    Salvar
                </v-btn>
            </div>
        </v-overlay>

    </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "ConversasView",

    data() {
        return {
            objects: [],
            totalObjects: 0,
            dialogConversation: false,
            departmentDialog: false,
            imageViewerDialog: null,
            media_url: '',
            changeTags: false,
            selectedConversation: {},
            messages: [],
            tags: [],
            departments: [],
            user_role_departments: [],
            text: "",
            loading: true,
            pre_filter: {
                text: '', changed: false, tags: [], departments: [], finished: { $exists: true },
                types: [
                    { name: 'Chatbot', id: 'chatbot', icon: 'mdi-robot-outline' },
                    { name: 'Humano', id: 'human', icon: 'mdi-monitor-account' }
                ]
            },
            can_clear: false,
            options: { filter: { text: '', finished: { $exists: true } } },
            types: [
                { name: 'Chatbot', id: 'chatbot', icon: 'mdi-robot-outline' },
                { name: 'Humano', id: 'human', icon: 'mdi-monitor-account' }
            ],
            tags_dictionary: {},
            headers: [
                { text: 'Foto', value: 'contact.profile_picture', sortable: false },
                { text: 'Nome', value: 'contact.name', sortable: false },
                { text: "Telefone", value: "contact.phone", sortable: false },
                { text: "Canal", value: "chatbot", sortable: false },
                { text: "Tags ou rótulos", value: "contact.tags", sortable: false },
                { text: "Departamento", value: "department", sortable: false },
                { text: 'Início', value: 'created', sortable: false },
                { text: 'Finalização', value: 'finished', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ]
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }
    },

    watch: {
        options: {
            handler() {
                this.getConversations(this.options);
            },
            deep: true,
        }
    },

    async mounted() {
        const tags_call = Api.getTagsByUser();
        const departments_call = Api.getDepartmentsByUser();

        const promisess = [await tags_call, await departments_call];

        const tags = promisess[0];
        const departments = promisess[1];

        if (!tags.error && tags.message) this.tags = tags.message;
        this.tags.forEach(i => this.tags_dictionary[i._id] = { name: i.name, color: i.color });

        if (!departments.error && departments.message) {
            this.departments = departments.message;

            if (this.currentUser.user_role == 'user') {
                this.user_role_departments = this.departments.filter(d => (this.currentUser.departments || []).includes(d._id.toString()));
            }
            else {
                this.user_role_departments = this.departments;
            }
        }

        this.buildFilter();
        await this.getConversations(this.options);
    },

    methods: {
        buildFilter() {
            this.options.page = 1;
            this.options.filter.types = this.pre_filter.types;
            this.options.filter.tags = this.pre_filter.tags;
            this.options.filter.text = this.pre_filter.text;

            //TRATANDO DEPARTAMENTOS
            if (this.currentUser.user_role == 'user') {
                if (!this.currentUser.departments) this.currentUser.departments = [];

                if (this.pre_filter.departments.length == 0) {
                    this.pre_filter.departments = this.user_role_departments;
                }
            }

            this.options.filter.departments = this.pre_filter.departments;
        },

        async getConversations(options) {
            this.loading = true;
            const resp = await Api.getConversationsByUser(options);
            this.loading = false;

            if (!resp.error && resp.message) {
                this.objects = resp.message.data;
                if (this.pre_filter.tags.length > 0) this.objects = this.objects.filter(i => this.pre_filter.tags.some(j => i.contact?.tags.includes(j._id)));
                this.totalObjects = resp.message.total;
            }
        },

        scrollMessagesToBottom(behavior = 'smooth') {
            const dialogElement = this.$refs.dialogRef;
            dialogElement.scrollTo({ top: dialogElement.scrollHeight + 500, behavior });
        },

        async getMessages(conversation) {
            this.selectedConversation = conversation;
            const resp = await Api.getMessagesByConversation(conversation._id);

            if (!resp.error && resp.message) {
                this.messages = resp.message;
                this.dialogConversation = true;
                setTimeout(() => {
                    this.scrollMessagesToBottom('instant');
                }, 100);
            }
        },

        clearFilters() {
            this.pre_filter = {
                text: '', changed: false, tags: [], departments: [],
                types: [
                    { name: 'Chatbot', id: 'chatbot', icon: 'mdi-robot-outline' },
                    { name: 'Humano', id: 'human', icon: 'mdi-monitor-account' }
                ]
            };
            this.can_clear = false;
            this.buildFilter();
            this.getConversations(this.options);
            this.filter_sheet = false;
        },

        applyFilters() {
            this.can_clear = true;
            this.buildFilter();
            this.getConversations(this.options);
            this.filter_sheet = false;
        },

        transferDepartment(item) {
            this.selectedConversation = item;
            this.changeTags = false;
            this.departmentDialog = true;
        },

        async changeContactTags() {
            await Api.changeContactTags(this.selectedConversation.contact);
        },

        showImage(media_url) {
            this.media_url = media_url;
            this.imageViewerDialog = true;
        },

        async changeConversationData() {
            this.loading = true;

            if (this.changeTags) await this.changeContactTags();

            let resp = await Api.changeConversationData(this.selectedConversation);
            if (!resp.error) {
                resp = await Api.resumeConversation(this.selectedConversation._id);
                if (!resp.error) {
                    emitToastr("success", "Atendimento retomado com sucesso");
                    this.selectedConversation = {};
                    this.messages = [];
                    this.dialogConversation = false;
                    this.getConversations(this.options);
                }
            }

            this.loading = false;
        }
    },
};
</script>

<style scoped>
.filter-menu-title {
    font-weight: 500;
    margin-right: 12px;
}

.contact-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.contact-info {
    display: flex;
    flex-direction: column;
    margin-left: 14px;
}

.contact-name {
    font-size: 18px;
    font-weight: 600;
}

.contact-phone {
    font-size: 14px;
}

.messages-container {
    display: flex;
    flex-direction: column;
}

.chat-item {
    max-width: 400px;
    padding: 8px;
    padding-bottom: 4px;
    margin-top: 6px;
    margin-bottom: 6px;
    border-radius: 6px;
    white-space: pre-line;
    word-wrap: break-word;
}

.chatbot-message {
    background-color: #e2ffc9;
    align-self: flex-end;
}

.customer-message {
    background-color: #ffffff;
    align-self: flex-start;
}

.message-time {
    text-align: right;
    font-size: 11px;
    color: #b1b1b1;
}

::v-deep .v-dialog {
    scrollbar-width: thin !important;
    scrollbar-color: rgba(74, 187, 52, 0.596) transparent !important;
}
</style>