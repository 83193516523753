<template>
    <div class="menu-page">
        <v-alert prominent class="success lighten-1" dark icon="mdi-school-outline">
            <v-row align="center">
                <v-col class="grow">
                    Quer aprender a criar fluxos incríveis? Entenda o funcionamento de cada componente assistindo nossos vídeos e tire proveito do que há de melhor na plataforma.
                </v-col>
                <v-col class="shrink" @click="$router.push('/ajuda')">
                    <v-btn>Ver vídeos</v-btn>
                </v-col>
            </v-row>
        </v-alert>

        <v-card>
            <v-card-title>
                Chatbots de fluxo
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-btn color="primary" @click.native="dialog = true">Novo fluxo</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-data-table :headers="headers" :items="items" :loading="loading">
                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="d-flex">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon :to="`/flows/${item._id}`">
                                                <v-icon medium color="primary" v-bind="attrs" v-on="on">mdi-file-edit-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click="selectedFlow = item; dialog2 = true">
                                                <v-icon medium color="primary" v-bind="attrs" v-on="on">mdi-content-duplicate</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Duplicar fluxo</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon @click="deleteItem(item)">
                                                <v-icon medium class="mr-2" color="error lighten-1" v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Apagar</span>
                                    </v-tooltip>
                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog" scrollable max-width="600" persistent>
            <v-card>
                <v-card-title>
                    Nome do fluxo
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="payload.name" label="Nome" outlined hide-details="auto" />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" outlined @click="dialog = false">Cancelar</v-btn>
                    <v-btn color="primary" @click="save" :loading="saving">Salvar</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog2" scrollable max-width="600" persistent>
            <v-card>
                <v-card-title>
                    Duplicar fluxo {{ selectedFlow.name }}
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="payload.name" label="Nome do novo fluxo" outlined hide-details="auto" />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" outlined @click="dialog2 = false">Cancelar</v-btn>
                    <v-btn color="primary" @click="duplicateFlow" :loading="saving">Duplicar</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import StartNode from '@/flow-editor/nodes/StartNode';

export default {
    name: "FlowList",
    data() {
        return {
            items: [],
            payload: {},
            dialog: false,
            dialog2: false,
            loading: false,
            saving: false,
            selectedFlow: {},
            headers: [
                { text: 'Nome', value: 'name', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false, width: 100 },
            ]
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }
    },
    async mounted() {
        await this.load();
    },
    methods: {
        async load() {
            this.loading = true;

            const response = await this.$api.getFlowsByUser();

            if (response.error) return

            this.items = response.message;

            setTimeout(() => {
                this.loading = false;
            }, 800);
        },
        async deleteItem(item) {
            const confirmDelete = await this.$store.dispatch('confirmDialog', {
                title: "Apagar fluxo",
                message: `Deseja realmente apagar o fluxo ${item.name}?`
            });

            if (!confirmDelete) return

            const response = await this.$api.createOrUpdateFlow({
                ...item,
                filed: true
            });

            if (response.error) return

            this.$toast("success", "Fluxo apagado com sucesso");

            await this.load();
        },

        async duplicateFlow() {
            if (!this.payload.name) {
                return this.$toast("error", "O nome é um campo necessário");
            }

            this.saving = true;
            const response = await this.$api.duplicateFlow(this.selectedFlow._id, this.payload.name);

            if (response.error) {
                this.saving = false;
                return
            }

            this.$toast("success", "Fluxo criado com sucesso");

            await this.load();

            setTimeout(() => {
                this.saving = false;
                this.dialog2 = false;
            }, 800);
        },

        async save() {
            if (!this.payload.name) {
                return this.$toast("error", "O nome é um campo necessário");
            }

            this.saving = true;

            const response = await this.$api.createOrUpdateFlow({
                name: this.payload.name,
                nodes: [new StartNode()],
            });

            if (response.error) {
                this.saving = false;
                return
            }

            this.$toast("success", "Fluxo salvo com sucesso");

            await this.load();

            setTimeout(() => {
                this.saving = false;
                this.dialog = false;
            }, 800);

        }
    },
};
</script>