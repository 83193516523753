import Vue from 'vue'
import Vuex from 'vuex'
import Api from '@/lib/Api'

Vue.use(Vuex)

const files = require.context('./modules', false, /\.js$/);

const SOCKET_IO_URL = process.env.NODE_ENV === "development" ? "http://localhost:4000" : "https://sock.inteligenciabot.com.br";

const modules = files.keys().reduce((acc, key) => {
    const name = key.replace(/(\.\/|\.js)/g, '')
    const module = files(key).default

    acc[name] = module

    return acc
}, {})


export default new Vuex.Store({
    modules,
    state: {
        currentUser: null,
        breadcrumbs: [],
        drawerMenuItems: [],
        pageTitle: 'InteligênciaBot',
        pageSubtitle: '',
        pageLoading: false,
        SOCKET_IO_URL: SOCKET_IO_URL,
        credits: 0,
        dialog: {
            show: false,
            title: '',
            message: '',
            resolve: null
        },
    },
    mutations: {
        setCurrentUser(state, payload) {
            state.currentUser = payload
        },
        setCredits(state, payload) {
            state.credits = payload
        },
        setBreadcrumbs(state, payload) {
            state.breadcrumbs = payload
        },
        setDrawerMenuItems(state, payload) {
            state.drawerMenuItems = payload
        },
        setPageTitle(state, payload) {
            state.pageTitle = payload

            document.title = payload || 'InteligênciaBot'
        },
        setPageSubtitle(state, payload) {
            state.pageSubtitle = payload
        },
        setPageLoading(state, payload) {
            state.pageLoading = payload
        },
        setDialog(state, payload) {
            state.dialog = payload
        },
    },
    actions: {
        async loadCurrentUser({ commit }) {
            const user = await Api.getRemoteUser();
            commit('setCurrentUser', user);
        },

        async confirmDialog({ commit }, payload) {
            return new Promise((resolve) => {
                commit('setDialog', {
                    show: true,
                    title: payload?.title || 'Confirmar ação',
                    message: payload?.message || 'Você tem certeza que deseja realizar esta ação?',
                    resolve
                })
            })
        }
    },
})