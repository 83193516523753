<template>
    <v-navigation-drawer class="drawer" v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" width="270" app dark>
        <router-link to="/meu-perfil" style="text-decoration: none;" id="v-step-0">
            <div class="d-flex flex-column align-center mt-8">
                <v-avatar size="80">
                    <v-img v-if="currentUser?.profile_picture" :src="currentUser?.profile_picture" />
                    <div v-else class="w-full h-full primary d-flex justify-center">
                        <v-icon color="white">mdi-account</v-icon>
                    </div>
                </v-avatar>

                <div class="white--text mt-3">
                    <div class="font-weight-400 text-subtitle-2">{{ currentUser?.name }}</div>
                </div>
            </div>
        </router-link>

        <div class="mt-3 d-flex flex-column align-center">
            <v-btn v-if="!chips_alert" x-small light class="primary" @click="dialog = true" style="color: black">Versão 2.2 | Novidades</v-btn>
            <v-btn v-if="chips_alert" x-small class="error lighten-1" @click="$router.push({ name: 'ChipNumbers' })">Canais desconectados!</v-btn>
        </div>

        <v-row class="mt-1">
            <v-col cols="12">
                <v-divider class="menu-divider "></v-divider>
            </v-col>
        </v-row>

        <v-list dense expand style="padding-bottom: 56px;">
            <v-list-item class="mb-2" to="/ajuda">
                <v-list-item-icon>
                    <v-icon color="white">mdi-help-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="white--text"><v-list-item-title>Como usar a plataforma</v-list-item-title></v-list-item-content>
            </v-list-item>

            <v-list-item to="/" v-if="['owner', 'manager'].includes(currentUser?.user_role)">
                <v-list-item-icon>
                    <v-icon color="green1">mdi-monitor-dashboard</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="white--text"><v-list-item-title>Dashboard</v-list-item-title></v-list-item-content>
            </v-list-item>

            <v-list-item to="/api" v-if="['owner', 'manager'].includes(currentUser?.user_role)">
                <v-list-item-icon>
                    <v-icon color="green1">mdi-api</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="white--text"><v-list-item-title>API</v-list-item-title></v-list-item-content>
            </v-list-item>

            <v-list-group class="mt-2" active-class="white--text" :value="true" style="min-width: 1px;" v-if="['owner', 'manager'].includes(currentUser?.user_role)">
                <template v-slot:activator>
                    <v-list-item-title class="white--text">Configurações de atendimento</v-list-item-title>
                </template>

                <v-list-item to="/chatbots" class="white--text" v-if="['owner', 'manager'].includes(currentUser?.user_role)">
                    <v-list-item-icon>
                        <span class="mx-3">
                            <v-icon color="white" class="green1--text">mdi-robot-outline</v-icon>
                        </span>
                    </v-list-item-icon>

                    <v-list-item-title>Chatbots de IA</v-list-item-title>
                </v-list-item>

                <v-list-item to="/flows" class="white--text" v-if="['owner', 'manager'].includes(currentUser?.user_role)">
                    <v-list-item-icon>
                        <span class="mx-3">
                            <v-icon color="white" class="green1--text">mdi-sitemap-outline</v-icon>
                        </span>
                    </v-list-item-icon>

                    <v-list-item-title>Chatbots de fluxo</v-list-item-title>
                </v-list-item>


                <v-list-item to="/chips" class="white--text" v-if="['owner', 'manager'].includes(currentUser?.user_role)">
                    <v-list-item-icon>
                        <span class="mx-3">
                            <v-icon color="white" class="green1--text">mdi-message-text-fast-outline</v-icon>
                        </span>
                    </v-list-item-icon>

                    <v-list-item-title>Canais de comunicação</v-list-item-title>
                </v-list-item>

                <v-list-item to="/equipe" class="white--text" v-if="['owner', 'manager'].includes(currentUser?.user_role)">
                    <v-list-item-icon>
                        <span class="mx-3">
                            <v-icon color="white" class="green1--text">mdi-account-group-outline</v-icon>
                        </span>
                    </v-list-item-icon>

                    <v-list-item-title>Minha equipe</v-list-item-title>
                </v-list-item>

                <v-list-item to="/mensagens-rapidas" class="white--text" v-if="['owner', 'manager'].includes(currentUser?.user_role)">
                    <v-list-item-icon>
                        <span class="mx-3">
                            <v-icon color="white" class="green1--text">mdi-message-fast-outline</v-icon>
                        </span>
                    </v-list-item-icon>

                    <v-list-item-title>Mensagens rápidas</v-list-item-title>
                </v-list-item>

                <v-list-item to="/tags" class="white--text" v-if="['owner', 'manager'].includes(currentUser?.user_role)">
                    <v-list-item-icon>
                        <span class="mx-3">
                            <v-icon color="white" class="green1--text">mdi-tag-outline</v-icon>
                        </span>
                    </v-list-item-icon>

                    <v-list-item-title>Tags ou rótulos</v-list-item-title>
                </v-list-item>

                <v-list-item to="/departamentos" class="white--text" v-if="['owner', 'manager'].includes(currentUser?.user_role)">
                    <v-list-item-icon>
                        <span class="mx-3">
                            <v-icon color="white" class="green1--text">mdi-briefcase-outline</v-icon>
                        </span>
                    </v-list-item-icon>

                    <v-list-item-title>Departamentos</v-list-item-title>
                </v-list-item>

            </v-list-group>

            <v-list-group class="mt-2" active-class="white--text" :value="true" style="min-width: 1px;">
                <template v-slot:activator>
                    <v-list-item-title class="white--text">Campanhas e agendamentos</v-list-item-title>
                </template>

                <v-list-item to="/audiencias" class="white--text">
                    <v-list-item-icon>
                        <span class="mx-3">
                            <v-icon color="white" class="green1--text">mdi-account-group-outline</v-icon>
                        </span>
                    </v-list-item-icon>

                    <v-list-item-title>Audiências</v-list-item-title>
                </v-list-item>

                <v-list-item to="/campanhas" class="white--text">
                    <v-list-item-icon>
                        <span class="mx-3">
                            <v-icon color="white" class="green1--text">mdi-message-fast-outline</v-icon>
                        </span>
                    </v-list-item-icon>

                    <v-list-item-title>Campanhas</v-list-item-title>
                </v-list-item>

                <v-list-item to="/agendamento" class="white--text">
                    <v-list-item-icon>
                        <span class="mx-3">
                            <v-icon color="white" class="green1--text">mdi-calendar-clock-outline</v-icon>
                        </span>
                    </v-list-item-icon>

                    <v-list-item-title>Envio individual</v-list-item-title>
                </v-list-item>
            </v-list-group>


            <v-list-item to="/contatos" v-if="['owner', 'manager', 'user'].includes(currentUser?.user_role)">
                <v-list-item-icon>
                    <v-icon color="green1">mdi-account-multiple-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="white--text"><v-list-item-title>Contatos</v-list-item-title></v-list-item-content>
            </v-list-item>

            <v-list-item to="/atendimento" v-if="['owner', 'manager', 'user'].includes(currentUser?.user_role)">
                <v-list-item-icon>
                    <v-icon color="green1">mdi-message-badge-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="white--text"><v-list-item-title>Conversas em andamento</v-list-item-title></v-list-item-content>
            </v-list-item>

            <v-list-item to="/conversas" v-if="['owner', 'manager', 'user'].includes(currentUser?.user_role)">
                <v-list-item-icon>
                    <v-icon color="green1">mdi-message-check-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="white--text"><v-list-item-title>Conversas finalizadas</v-list-item-title></v-list-item-content>
            </v-list-item>
        </v-list>

        <v-dialog v-model="dialog" width="600" scrollable>
            <v-card>
                <v-card-title>Novidades da plataforma</v-card-title>
                <v-card-subtitle>Acompanhe aqui as novas funcionalidades assim que são lançadas</v-card-subtitle>

                <v-card-text>
                    <div class="py-2">
                        <div><b>Versão 2.2 - 08/08/2024</b></div>
                        <div>● Fluxo: componente temporizador de envio (atraso programado no envio das mensagens).</div>
                        <div>● Fluxo: componente randomizador (distribuição randômica)</div>
                    </div>
                    <v-divider></v-divider>
                    <div class="py-2">
                        <div><b>Versão 2.1 - 30/08/2024</b></div>
                        <div>● Gestão de conversas que estão aguardando resposta. Dashboard aprimorado.</div>
                        <div>● Botão de duplicar fluxo.</div>
                        <div>● Assinaturas atrasadas não terão seus canais desconectados automaticamente. Apenas as canceladas.</div>
                        <div>● Correção do bug na criação de atendente com departamentos selecionados.</div>
                        <div>● Correção do bug que não atualizava em tempo real algumas novas conversas que deveriam chegar na página de conversas em andamento.</div>
                        <div>● Correção do bug que não exibia documentos PDF com legenda.</div>
                        <div>● Melhorias na estabilidade da conexão com Whatsapp.</div>
                    </div>
                    <v-divider></v-divider>

                    <div class="py-2">
                        <div><b>Versão 2.0 - 24/08/2024</b></div>
                        <div>● API agora pode disparar fluxos, criando a possibilidade de implementações de integrações complexas.</div>
                        <div>● Campanha e agendamento de fluxo agora removem o status de atendimento humano.</div>
                        <div>● Todos os áudios enviados via fluxo serão considerados como gravados na hora.</div>
                        <div>● Correções de bugs e melhorias de desempenho.</div>
                    </div>
                    <v-divider></v-divider>

                    <div class="py-2">
                        <div><b>Versão 1.9 - 20/08/2024</b></div>
                        <div>● Agora chatbots de IA saberão data e hora atuais.</div>
                        <div>● Correção da foto de perfil dos contatos.</div>
                        <div>● Correção do bug de filtros nas conversas em andamento.</div>
                        <div>● Melhoria no sistema de reconexão do Whatsapp.</div>
                    </div>
                    <v-divider></v-divider>

                    <div class="py-2">
                        <div><b>Versão 1.8 - 13/08/2024</b></div>
                        <div>● Acompanhamento de campanhas em andamento em tempo real: quando a campanha está enviando mensagens, um novo botão "Acompanhar" fica disponível, mostrando as principais informações dos envios.</div>
                        <div>● Possibilidade de cancelar campanhas em andamento.</div>
                        <div>● Campanhas e envios agendados de TEXTO (não fluxo) são enviados normalmente, porém agora NÃO criam atendimentos/conversas automaticamente, até que o contato responda a mensagem. Ao responder, a conversa será criada de acordo com o primeiro atendimento cadastrado no canal: chatbot de IA, chatbot de fluxo ou atendimento humano.</div>
                    </div>
                    <v-divider></v-divider>

                    <div class="py-2">
                        <div><b>Versão 1.7 - 11/08/2024</b></div>
                        <div>● Área de dados do contato com tags, variáveis e anotações: Na área de contatos ou nas conversas em andamento, acesse uma conversa e clique no nome do contato ou no menu e acesse "Dados do contato".</div>
                        <div>● Correções diversas nas campanhas e no fluxo.</div>
                        <div>● Melhoria no desempenho dos chatbots de IA.</div>
                    </div>
                    <v-divider></v-divider>

                    <div class="py-2">
                        <div><b>Versão 1.6 - 31/07/2024</b></div>
                        <div>● Construtor de fluxos.</div>
                        <div>● Possibilidade de ter chatbots de IA enviando para fluxos e fluxos enviando para IA.</div>
                        <div>● Campanhas podem disparar fluxos.</div>
                        <div>● Agendamentos individuais podem disparar fluxos.</div>
                        <div>● Novos tutoriais na área "Como usar a plataforma".</div>
                        <div>● Conversas por chatbot agora finalizam automaticamente após 48 horas de inatividade (antes eram 30 minutos).</div>
                        <div>● Possibilidade de encerrar atendimentos de chatbot sem precisar transferir para humano antes.</div>
                    </div>
                    <v-divider></v-divider>

                    <div class="py-2">
                        <div><b>Versão 1.5 - 03/07/2024</b></div>
                        <div>● Emojis no campo de mensagem (atendimento humano).</div>
                        <div>● Possibilidade de exportar contatos.</div>
                        <div>● Som de notificação ao receber nova mensagem (tela conversas em andamento).</div>
                        <div>● Chatbot não responde mais "por cima" do atendimento humano.</div>
                        <div>● Melhorias no agendamento de mensagem individual.</div>
                        <div>● Melhorias gerais de UX.</div>
                    </div>
                    <v-divider></v-divider>

                    <div class="py-2">
                        <div><b>Versão 1.4 - 23/06/2024</b></div>
                        <div>● API de envio de mensagens via sistemas externos.</div>
                        <div>● Paginação nas conversas em andamento.</div>
                        <div>● Correções de bugs menores.</div>
                    </div>
                    <v-divider></v-divider>

                    <div class="py-2">
                        <div><b>Versão 1.3 - 16/06/2024</b></div>
                        <div>● Sistema de tagueamento automático por palavras-chave (para usar, edite suas tags).</div>
                        <div>● Possibilidade de transferência para humano quando o chatbot receber imagens ou documentos (nas configurações do chatbot).</div>
                        <div>● Correção do bug nos filtros de tag da tela de conversas finalizadas.</div>
                        <div>● Agora o chat mostra quando uma mensagem do usuário é uma resposta a outra mensagem, trazendo o conteúdo original.</div>
                        <div>● Melhorias de desempenho.</div>
                    </div>
                    <v-divider></v-divider>
                    <div class="py-2">
                        <div><b>Versão 1.2 - 06/06/2024</b></div>
                        <div>● Nome do autor da mensagem (atendente) exibido na plataforma (não é exibido para o contato).</div>
                        <div>● Atalho para gravação de áudio na conversa.</div>
                        <div>● Possibilidade de envio de midia sem salvar na biblioteca.</div>
                        <div>● Melhoria no layout da biblioteca de midia.</div>
                        <div>● Possibilidade de conectar canais diretamente com o atendimento humano.</div>
                    </div>
                    <v-divider></v-divider>
                    <div class="py-2">
                        <div><b>Versão 1.1 - 31/05/2024</b></div>
                        <div>● Dashboard com filtro por canal / número conectado.</div>
                        <div>● Sistema de atraso no envio de mensagens por chatbot: Evita que seu chatbot responda imediatamente a uma pergunta. Pode ser configurado na área "Chatbots inteligentes", editando seu chatbot. Seção "ajustes gerais", opção "Atraso na resposta".</div>
                        <div>● Comportamento humano: agora o chatbot aguarda 2 segundos entre visualizar a mensagem e começar a digitar. Também digita por 5 segundos antes de enviar a resposta.</div>
                    </div>
                    <v-divider></v-divider>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-navigation-drawer>
</template>

<script>
import Api from '@/lib/Api';

export default {
    name: 'AppDrawer',
    data() {
        return {
            dialog: false,
            chips_alert: false,
        }
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    mounted() {
        this.getChips();

        //CHECAGEM BÁSICA DE CANAIS A CADA 5 MINUTOS
        setInterval(() => {
            this.getChips();
        }, 5 * 60 * 1000);
    },
    computed: {
        drawer: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        currentUser() {
            return this.$store.state.currentUser
        },
        isAdmin() {
            return this.currentUser?.admin;
        },
        menuItems: {
            get() {
                return this.$store.state.drawerMenuItems;
            },
            set(value) {
                this.$store.commit('setDrawerMenuItems', value);
            }
        }
    },
    methods: {
        async getChips() {
            this.chips_alert = false;
            const resp = await Api.getChipNumbersByUser();

            if (!resp.error && resp.message) {
                const chips = resp.message;
                const open_chips = resp.message.filter(c => c.status == 'open');

                if (chips.length > 0 && open_chips.length == 0) this.chips_alert = true;

            }
        },

        setMenuItems() {
            this.menuItems = []

            if (!this.currentUser) {
                return;
            }
        }
    },
    watch: {
        currentUser: {
            immediate: true,
            handler: 'setMenuItems'
        }
    },
}
</script>

<style scoped>
.menu-divider {
    width: 90%;
    margin: auto;
    opacity: 0.6;
}

.drawer {
    background: rgb(38, 38, 38);
    z-index: 100;  
}

::v-deep .v-navigation-drawer__content {
    scrollbar-width: thin !important;
    scrollbar-color: rgba(74, 187, 52, 0.322) transparent !important;
}
</style>