<template>
    <div class="menu-page">
        <div v-if="!currentUser.account.campaigns_enabled">
            <v-alert class="fade" dense outlined type="warning">
                Funcionalidade indisponível no Plano Solo. Por favor, faça upgrade para um plano superior.
                <v-btn class="ml-2" color="warning" @click.native="$router.push('/minha-conta')">Ver planos disponíveis</v-btn>
            </v-alert>
        </div>

        <div v-if="currentUser.account.campaigns_enabled">
            <v-alert class="fade" dense outlined type="error" dismissible>
                Aviso importante: a funcionalidade de campanha (envio em massa) deve ser utilizada com cautela, pois o envio exagerado ou o envio para audiências não qualificadas pode causar banimento do chip, uma vez que as pessoas podem denunciar spam. Sempre tente enviar via chips alternativos já
                "quentes", para audiências qualificadas, com bom intervalo entre as mensagens, e com variações de versões. Seguindo as boas práticas você poderá fazer suas campanhas com segurança.
            </v-alert>
            <v-card>
                <v-card-title>
                    Campanhas
                </v-card-title>

                <v-card-text v-if="showAlert || showAlert2">
                    <v-alert v-if="showAlert" class="fade" dense outlined type="error" dismissible>
                        Sua conta não possui nenhum número conectado. Será necessário conectar um número/chip para fazer o envio.
                        <div>
                            <v-btn class="mt-2" color="error" @click.native="$router.push('/chips')">Conectar número</v-btn>
                        </div>
                    </v-alert>


                    <!--
                    <v-alert v-if="showAlert2" class="fade" dense outlined type="warning" dismissible>
                        Sua conta ainda não possui audiências cadastradas. Crie pelo menos uma antes de iniciar uma campanha.
                        <div>
                            <v-btn class="mt-2" color="warning" @click.native="$router.push('/audiencia')">Cadastrar audiência</v-btn>
                        </div>
                    </v-alert>
                    -->

                </v-card-text>

                <v-card-text>
                    <div v-if="!showAlert">
                        <v-row>
                            <v-col cols="12">
                                <v-btn color="primary" @click.native="openNew">Nova campanha</v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :headers="headers" :items="objects">

                                    <template v-slot:[`item.created`]="{ item }">
                                        {{ new Date(item.created).toLocaleString('pt-BR').replace(',', ' -') }}
                                    </template>

                                    <template v-slot:[`item.scheduledTo`]="{ item }">
                                        {{ item.scheduledTo ? new Date(item.scheduledTo).toLocaleString('pt-BR').replace(',', ' -') : 'Imediato' }}
                                    </template>

                                    <template v-slot:[`item.status`]="{ item }">
                                        {{ statuses[item.status] }}
                                    </template>

                                    <template v-slot:[`item.audience`]="{ item }">
                                        {{ getAudience(item.audience) }}
                                    </template>

                                    <template v-slot:[`item.sent_messages`]="{ item }">
                                        <v-tooltip top v-if="item.status === 'started'">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon medium color="primary" @click="selectedItem = item; openRealTime(item._id)" v-bind="attrs" v-on="on">mdi-eye-outline</v-icon>
                                            </template>
                                            <span>Acompanhar em tempo real</span>
                                        </v-tooltip>

                                        <div v-if="item.status != 'started'">{{ item.sent_messages }}</div>
                                    </template>


                                    <template v-slot:[`item.actions`]="{ item }">
                                        <div class="d-flex">

                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon class="mr-3" medium color="error" @click="object = item; dialog = true" v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
                                                </template>
                                                <span>Apagar</span>
                                            </v-tooltip>

                                            <v-tooltip top v-if="!['canceled', 'finished'].includes(item.status)">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon class="mr-3" medium color="error" @click="object = item; dialog2 = true" v-bind="attrs" v-on="on">mdi-close-circle</v-icon>
                                                </template>
                                                <span>Cancelar</span>
                                            </v-tooltip>

                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon class="mr-3" medium color="primary" @click="openObject(item)" v-bind="attrs" v-on="on">mdi-file-edit-outline</v-icon>
                                                </template>
                                                <span>Editar</span>
                                            </v-tooltip>

                                            <v-tooltip top v-if="item.status === 'started'">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon medium color="primary" @click="selectedItem = item; openRealTime(item._id)" v-bind="attrs" v-on="on">mdi-eye-outline</v-icon>
                                                </template>
                                                <span>Acompanhar em tempo real</span>
                                            </v-tooltip>
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>

            <v-dialog v-model="dialogCreate" scrollable max-width="1200">
                <v-card class="mb-4" color="background">
                    <v-card-title>
                        Campanha
                    </v-card-title>

                    <v-card-subtitle class="mb-4">
                        Envie mensagens em massa para um público cadastrado.
                    </v-card-subtitle>

                    <v-card-text class="pt-1">
                        <v-row>
                            <v-col cols="12" lg="6" md="6" sm="12">
                                <v-text-field v-model="object.name" label="Nome da campanha" placeholder="Insira o nome da campanha" outlined hide-details="auto" />
                            </v-col>

                            <v-col cols="12" lg="6" md="6" sm="12">
                                <v-select clearable v-model="object.chip" item-text="name" item-value="_id" :items="chips" label="Chip de envio" outlined hide-details="auto"></v-select>
                            </v-col>

                            <v-col cols="12" lg="6" md="6" sm="12">
                                <v-select clearable v-model="type" item-text="name" item-value="id" :items="types" label="Público de envio" outlined hide-details="auto"></v-select>
                            </v-col>

                            <v-col cols="12" lg="6" md="6" sm="12" v-if="type == 'audience'">
                                <v-select clearable v-model="object.audience" item-text="name" item-value="_id" :items="audiences" label="Público ou audiência" outlined hide-details="auto"></v-select>
                            </v-col>

                            <v-col cols="12" lg="6" md="6" sm="12" v-if="type == 'tags'">
                                <v-select chips small-chips multiple outlined v-model="object.audience_tags" :items="tags" item-text="name" item-value="_id" label="Que possua qualquer uma das tags" hide-details="auto">
                                    <template #selection="{ item }">
                                        <v-chip small :color="item.color">{{ item.name }}</v-chip>
                                    </template>
                                </v-select>
                            </v-col>

                            <v-col cols="12" lg="6" md="6" sm="12">
                                <v-select clearable v-model="object.message_type" item-text="name" item-value="id" :items="message_types" label="Tipo de conteúdo" outlined hide-details="auto"></v-select>
                            </v-col>

                            <v-col cols="12" lg="6" md="6" sm="12" v-if="object.message_type == 'flow'">
                                <v-select clearable v-model="object.flow" item-text="name" item-value="_id" :items="flows" label="Fluxo" outlined hide-details="auto"></v-select>
                            </v-col>

                            <v-col cols="12" lg="6" md="6" sm="12">
                                <v-select clearable v-model="object.interval" :items="intervals" label="Intervalo entre mensagens (segundos)" outlined hide-details="auto" messages="Intervalos maiores são mais seguros contra bloqueios de chip"></v-select>
                            </v-col>

                            <v-col cols="12" lg="6" md="6" sm="12">
                                <v-datetime-picker label="Data e hora do envio" v-model="object.scheduledTo" date-format="dd/MM/yyyy" :datePickerProps="{ locale: 'pt-BR' }" :timePickerProps="{ format: '24hr' }" :textFieldProps="{ outlined: true, 'hide-details': 'auto' }">
                                    <template slot="dateIcon">
                                        <v-icon>mdi-calendar</v-icon>
                                    </template>

                                    <template slot="timeIcon">
                                        <v-icon>mdi-clock-outline</v-icon>
                                    </template>

                                    <template slot="actions" slot-scope="{ parent }">
                                        <v-btn color="error lighten-1" outlined @click.native="parent.clearHandler">Cancelar</v-btn>
                                        <v-btn color="success en-1" @click="parent.okHandler">OK</v-btn>
                                    </template>
                                </v-datetime-picker>
                            </v-col>
                            <v-col cols="12" v-if="object.message_type == 'text'">
                                <v-divider></v-divider>
                                <div class="content-title">Conteúdo da mensagem</div>
                                <div class="content-subtitle">Mensagens do tipo texto serão enviadas, mas só criarão uma nova conversa na plataforma após a resposta do contato (para evitar que o chatbot interfira em conversas pessoais iniciadas por você diretamente no celular)</div>
                                <div class="content-subtitle">O sistema sorteará uma das versões para cada pessoa da audiência. Quanto mais versões, mais segurança contra bloqueios de chip</div>
                            </v-col>

                            <v-row v-if="object.message_type == 'text'">
                                <v-col cols="12" v-for="(text, index) in object.message" :key="index" class="d-flex">
                                    <v-textarea v-model="object.message[index]" rows="4" :label="`Versão ${index + 1} da mensagem`" placeholder="Escreva aqui a mensagem de texto que será enviada." outlined hide-details="auto" />
                                    <v-btn :disabled="index == 0" icon color="error lighten-2" class="ml-2" @click="object.message.splice(index, 1)"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                                </v-col>

                                <v-col cols="12">
                                    <v-btn small color="primary" outlined @click="object.message.push('Insira o texto aqui')">Nova versão</v-btn>
                                </v-col>
                            </v-row>

                        </v-row>
                    </v-card-text>

                    <v-card-actions class="d-flex flex-wrap">
                        <v-spacer></v-spacer>
                        <v-btn class="mr-2" color="primary" outlined @click.native="getObjects(); dialogCreate = false">Cancelar</v-btn>
                        <v-btn class="my-1" color="primary" @click.native="createNew" :loading="loading">Salvar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <v-dialog v-model="dialogRealTime" scrollable max-width="1200">
                <v-card class="mb-4" color="background">
                    <v-card-title>
                        Campanha
                    </v-card-title>

                    <v-card-subtitle class="mb-4">
                        Acompanhe em tempo real o envio das campanhas em andamento
                    </v-card-subtitle>

                    <v-card-text class="pt-1">
                        <v-row>
                            <v-col cols="12">
                                <v-progress-linear :value="100 * real_time_data.sent_messages / (real_time_data.sent_messages + real_time_data.next_messages)" v-if="(real_time_data?.sent_messages)" height="30" rounded>
                                    <template v-slot:default="{ value }">
                                        <div class="text-caption-total">{{ Math.ceil(value) }}%</div>
                                    </template>
                                </v-progress-linear>
                            </v-col>

                            <v-col cols="12" class="subtitle-1" v-if="real_time_data?.estimated_finish_time">
                                <b>Previsão de término:</b> {{ new Date(real_time_data.estimated_finish_time).toLocaleString('pt-BR') }}
                            </v-col>

                            <v-col cols="12" v-if="last_message?.type">
                                <v-alert dense text type="success">
                                    <strong>{{ last_message.to }}</strong> {{ last_message.type === 'flow' ? ' recebeu uma mensagem de fluxo!' : ` recebeu a mensagem "${last_message.text}"` }}
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="d-flex flex-wrap">
                        <v-spacer></v-spacer>
                        <v-btn class="mr-2" color="primary" outlined @click.native="dialogRealTime = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog overlay-opacity="0.5" overlay-color="white" v-model="dialog" max-width="350">
                <v-card>
                    <v-card-title class="text-h5">
                        Apagar campanha
                    </v-card-title>
                    <v-card-text>Tem certeza que deseja apagar a campanha <b>{{ object.name }}</b>?</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="dialog = false">
                            Cancelar
                        </v-btn>
                        <v-btn color="error" text @click="dialog = false; deleteObject(object)">
                            Apagar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog overlay-opacity="0.5" overlay-color="white" v-model="dialog2" max-width="350">
                <v-card>
                    <v-card-title class="text-h5">
                        Cancelar campanha
                    </v-card-title>
                    <v-card-text>Tem certeza que deseja cancelar a campanha <b>{{ object.name }}</b>?</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="dialog2 = false">
                            Não
                        </v-btn>
                        <v-btn color="error" text @click="dialog2 = false; cancelObject(object)">
                            Sim, tenho certeza
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </div>
    </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
let socket;

export default {
    name: "CampanhasView",

    data() {
        return {
            objects: [],
            object: { to: "", message: [""], scheduled: false, message_type: "text" },
            chips: [],
            audiences: [],
            flows: [],
            selectedItem: {},
            type: "audience",
            types: [
                { name: "Audiência cadastrada", id: "audience" },
                { name: "Por tags", id: "tags" },
            ],
            tags: [],
            statuses: {
                "scheduled": "Agendada",
                "error": "Erro",
                "finished": "Concluída",
                "canceled": "Cancelada",
                "started": "Iniciada",
            },
            message_types: [
                { id: 'text', name: 'Texto' },
                { id: 'flow', name: 'Fluxo' },
            ],
            intervals: [20, 30, 60, 90, 120, 180, 240],
            showAlert: false,
            showAlert2: false,
            dialog: false,
            dialog2: false,
            dialogCreate: false,
            dialogRealTime: false,
            loading: false,
            real_time_data: {},
            last_message: {},
            headers: [
                { text: 'Nome', value: 'name' },
                { text: 'Criação', value: 'created' },
                { text: 'Agendamento', value: 'scheduledTo' },
                { text: 'Público', value: 'audience' },
                { text: 'Alcance estimado', value: 'people_count' },
                { text: 'Envios realizados', value: 'sent_messages' },
                { text: "Status", value: "status", sortable: false },
                { text: "Ações", value: "actions", sortable: false }
            ]
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }
    },

    async beforeDestroy() {
        socket.disconnect();
        socket = null;
    },

    async mounted() {
        this.getTags();
        this.startSocket();
        await this.getObjects();

        const chips_call = Api.getChipNumbersByUser();
        const audiences_call = Api.getAudienceByUser();
        const flows_call = Api.getFlowsByUser();

        const [chips, audiences, flows] = await Promise.all([chips_call, audiences_call, flows_call]);

        if (!chips.error && chips.message) {
            this.chips = chips.message.filter(c => c.status == 'open');
            if (this.chips.length < 1) this.showAlert = true;
        }

        if (!audiences.error && audiences.message) this.audiences = audiences.message;
        if (this.audiences.length < 1) this.showAlert2 = true;

        if (!flows.error && flows.message) this.flows = flows.message;
    },

    methods: {
        async getObjects() {
            const resp = await Api.getCampaignsByUser();

            if (!resp.error && resp.message) {
                this.objects = resp.message;
            }
        },

        startSocket() {
            socket = this.$io(this.$store.state.SOCKET_IO_URL);

            socket.on("connect", async () => {

            });

            socket.on("receive-message", data => {
                if (data.event === "new_message") {
                    this.last_message = data;
                    this.getRealTimeData();
                }
            });
        },

        async getRealTimeData() {
            const resp = await Api.getCampaignRealTimeData(this.selectedItem);

            if (!resp.error && resp.message) {
                this.real_time_data = resp.message;
            }
        },

        async getTags() {
            const resp = await Api.getTagsByUser();

            if (!resp.error && resp.message) {
                this.tags = resp.message;
            }
        },

        getAudience(audience_id) {
            return this.audiences.find(a => a._id?.toString() == audience_id?.toString())?.name || "N/A";
        },

        async createNew() {
            if (!this.object.chip) return emitToastr("error", "Chip de envio necessário");
            if (!this.object.scheduledTo) return emitToastr("error", "Data e hora de envio necessária");

            if (this.type == 'audience') {
                if (!this.object.audience) return emitToastr("error", "Audiência necessária para formar o público de envio");
            }
            else {
                //TAGS
                if (!this.object.audience_tags || this.object.audience_tags.length == 0) return emitToastr("error", "Tags necessárias para formar o público de envio");
            }

            if (this.object.message_type === 'text') {
                for (const i in this.object.message) {
                    if (this.object.message[i].trim() == "") return emitToastr("error", "As versões de mensagem não podem estar vazias. Escreva o texto de todas as versões.");
                }
            }

            this.loading = true;
            let resp;
            this.object.type = this.type;

            if (this.object._id) {
                resp = await Api.updateCampaign(this.object);
            }
            else {
                resp = await Api.createCampaign(this.object);
            }

            this.loading = false;

            if (!resp.error) {
                this.object = { message: [""], scheduled: false };
                this.dialogCreate = false;
                emitToastr("success", "Campanha cadastrada com sucesso.");
                setTimeout(() => {
                    this.getObjects();
                }, 2000);
            }
        },

        async deleteObject(object) {
            object.filed = true;
            await Api.updateCampaign(object);
            this.getObjects();
            this.object = { message: [""], scheduled: false };
        },

        async cancelObject(object) {
            object.cancel_now = true;
            await Api.updateCampaign(object);
            this.getObjects();
            this.object = { message: [""], scheduled: false };
        },

        openNew() {
            this.object = { message: [""], scheduled: false };
            this.dialogCreate = true;
        },

        openObject(item) {
            item.scheduledTo = new Date(item.scheduledTo);
            this.object = item;
            this.dialogCreate = true;
        },

        openRealTime(campaing_id = "") {
            this.getRealTimeData();
            this.dialogRealTime = true;

            socket.emit("leave-all-rooms", ``);
            socket.emit("join-room", `account_campaign_${campaing_id.toString()}`);
        }
    },
};
</script>

<style scoped>
.content-title {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 600;
}
</style>