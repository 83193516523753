<template>
    <v-list-item-subtitle>
        <v-icon v-if="origin != 'customer'" size="16" :color="status >= 4 ? 'blue' : 'grey lighten-1'">mdi-check-all</v-icon>
        {{ message }}
    </v-list-item-subtitle>
</template>

<script>

export default {
    name: 'MessagePreview',
    props: {
        origin: {
            type: String,
            default: "customer"
        },
        type: {
            type: String,
            default: "text"
        },
        message: {
            type: String,
            default: ""
        },
        status: {
            type: Number,
            default: 1
        },
    }
}

</script>
