<template>
    <div class="menu-page">
        <v-card>
            <v-card-title>
                Tags / Rótulos
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-btn color="primary" @click.native="openNew">Nova tag</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-data-table :headers="headers" :items="objects" :loading="loading">

                            <template v-slot:[`item.color`]="{ item }">
                                <div class="color-preview" :style="`background-color:${item.color}`">

                                </div>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="d-flex">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon medium class="mr-2" color="error lighten-1" @click="confirmDeleteObject(item)" v-bind="attrs" v-on="on">mdi-trash-can-outline</v-icon>
                                        </template>
                                        <span>Apagar</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon medium color="primary" @click="openObject(item)" v-bind="attrs" v-on="on">mdi-file-edit-outline</v-icon>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog" scrollable max-width="600" persistent>
            <v-card>
                <v-card-title>
                    Tag ou rótulo
                </v-card-title>
                <v-card-subtitle>
                    Crie ou modifique suas tags para organizar os atendimentos de forma simplificada e eficiente
                </v-card-subtitle>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field class="pt-1" v-model="object.name" label="Nome da tag ou rótulo" outlined hide-details="auto" />
                        </v-col>
                        <v-col cols="12">
                            <v-switch inset v-model="object.auto_tag" label="Associar automaticamente com palavras-chave"></v-switch>
                        </v-col>

                        <v-col cols="12" v-if="object.auto_tag">
                            <v-text-field class="pt-1" v-model="new_keyword" label="Nova palavra-chave" placeholder="Digite a aperte ENTER" outlined hide-details="auto" @keyup.enter="addItem(new_keyword)" />
                        </v-col>

                        <v-col cols="12" v-if="object.auto_tag">
                            <label>Palavras-chave enviadas pelo contato que irão associá-lo com a tag {{ object.name }}</label>
                            <div>
                                <v-chip v-for="(item, index) in object.keywords" dark close @click:close="object.keywords.splice(index, 1)" class="primary mr-2 my-2" :key="index">{{ item }}</v-chip>

                                <v-chip small class="warning" v-if="!object.keywords || object.keywords.length == 0"> Crie pelo menos uma palavra-chave </v-chip>
                            </div>
                        </v-col>

                        <v-col cols="12" class="d-flex">
                            <h3 class="mr-2">Cor de identificação</h3>
                            <input type="color" v-model="object.color" />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" outlined @click.native="dialog = false">Cancelar</v-btn>
                    <v-btn color="primary" @click.native="save" :loading="loading">Salvar</v-btn>
                </v-card-actions>

            </v-card>

        </v-dialog>


        <v-dialog overlay-opacity="0.5" overlay-color="black" v-model="deleteDialog" max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Apagar tag
                </v-card-title>
                <v-card-text>Tem certeza que deseja apagar a tag <b>{{ object.name }}</b>?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="deleteDialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="error" text @click="deleteDialog = false; deleteObject()">
                        Apagar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
//import { emitToastr } from "@/lib/Utils";


export default {
    name: "MensagensRapidasView",

    data() {
        return {
            objects: [],
            object: { color: '#00FF00', keywords: [] },
            new_keyword: '',
            loading: true,
            dialog: false,
            deleteDialog: false,
            headers: [
                { text: 'Nome', value: 'name', sortable: false },
                { text: 'Cor', value: 'color', sortable: false },
                { text: 'Ações', value: 'actions', sortable: false },
            ]
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        }
    },


    async mounted() {
        await this.getObjects();
    },

    methods: {
        async getObjects() {
            this.loading = true;
            const resp = await Api.getTagsByUser();
            this.loading = false;

            if (!resp.error && resp.message) {
                this.objects = resp.message;
            }
        },

        openNew() {
            this.object = { color: '#00FF00', keywords: [] };
            this.dialog = true;
        },

        confirmDeleteObject(item) {
            this.object = item;
            this.deleteDialog = true;
        },

        async deleteObject() {
            this.object.filed = true;
            const resp = await Api.createOrUpdateTag(this.object);
            if (!resp.error && resp.message) {
                emitToastr("success", "Tag apagada com sucesso");
                this.object = {};
                this.getObjects();
            }
        },

        openObject(item) {
            this.object = item;
            this.dialog = true;
        },

        addItem(item) {
            if (!item || item == '') return emitToastr("error", "Palavra-chave necessária");
            if (this.object.keywords.find(k => k.toLowerCase() == item.toLowerCase())) return emitToastr("error", "Palavra-chave já cadastrada");
            this.object.keywords.push(item);
            this.new_keyword = "";
        },

        async save() {
            if (!this.object.name) return emitToastr("error", "O nome é um campo necessário");
            if (!this.object.color) return emitToastr("error", "A cor é um campo necessário");
            if (this.object.auto_tag && (!this.object.keywords || this.object.keywords.length == 0)) return emitToastr("error", "Sem palavra-chave cadastrada. Desative a opção de associar automaticamente com palavras-chave ou cadastre ao menos uma.");

            if (!this.object.auto_tag) this.object.keywords = [];

            if (this.new_keyword != '') this.addItem(this.new_keyword);

            this.loading = true;
            const resp = await Api.createOrUpdateTag(this.object);
            this.loading = false;

            if (!resp.error) {
                emitToastr("success", "Tag salva com sucesso");
                this.getObjects();
                this.dialog = false;
            }
        }
    },
};
</script>

<style scoped>
.color-preview {
    width: 80px;
    height: 25px;
    border-radius: 5px;
}
</style>