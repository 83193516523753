import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
//import Cadastro from "../views/Cadastro.vue";
import Dashboard from "../views/Dashboard.vue";
import AdminDashboard from "../views/AdminDashboard.vue";
import Perfil from "../views/Perfil.vue";
import Conta from "../views/Conta.vue";
import ChipNumbers from "../views/ChipNumbers.vue";
import Chatbots from "../views/Chatbots.vue";
import Contatos from "../views/Contatos.vue";
import Conversas from "../views/Conversas.vue";
import Atendimento from "../views/Atendimento.vue";
import MensagensRapidas from "../views/MensagensRapidas.vue";
import Tags from "../views/Tags.vue";
import Departments from "../views/Departments.vue";
import Equipe from "../views/Equipe.vue";
import MensagensAgendadas from "../views/MensagensAgendadas.vue";
import Audiencias from "../views/Audiencias.vue";
import Campanhas from "../views/Campanhas.vue";
import Ajuda from "../views/Ajuda.vue";
import Api from "../views/Api.vue";
import FlowList from "../views/FlowList.vue";
import FlowSigle from "../views/FlowSingle.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      layout: "fullscreen",
    },
  },
  /*
  {
    path: "/cadastro",
    name: "cadastro",
    component: Cadastro,
    meta: {
      layout: "fullscreen",
    },
  },
  */
  {
    path: "/ajuda",
    name: "Ajuda",
    component: Ajuda,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/dash-admin",
    name: "Admin Dashboard",
    component: AdminDashboard,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/meu-perfil",
    name: "Perfil",
    component: Perfil,
    meta: {
      layout: "menu",
      title: "Meu Perfil",
    },
  },
  {
    path: "/api",
    name: "Api",
    component: Api,
    meta: {
      layout: "menu",
      title: "Api",
    },
  },
  {
    path: "/minha-conta",
    name: "Conta",
    component: Conta,
    meta: {
      layout: "menu",
      title: "Minha Conta",
    },
  },
  {
    path: "/chips",
    name: "ChipNumbers",
    component: ChipNumbers,
    meta: {
      layout: "menu",
      title: "Números / Chips",
    },
  },
  {
    path: "/chatbots",
    name: "Chatbots",
    component: Chatbots,
    meta: {
      layout: "menu",
      title: "Chatbots Inteligentes",
    },
  },
  {
    path: "/contatos",
    name: "Contatos",
    component: Contatos,
    meta: {
      layout: "menu",
      title: "Contatos",
    },
  },
  {
    path: "/conversas",
    name: "Conversas",
    component: Conversas,
    meta: {
      layout: "menu",
      title: "Conversas",
    },
  },
  {
    path: "/atendimento",
    name: "Atendimento",
    component: Atendimento,
    meta: {
      layout: "menu",
      title: "Atendimento",
    },
  },
  {
    path: "/mensagens-rapidas",
    name: "MensagensRapidas",
    component: MensagensRapidas,
    meta: {
      layout: "menu",
      title: "Mensagens Rápidas",
    },
  },
  {
    path: "/tags",
    name: "Tags",
    component: Tags,
    meta: {
      layout: "menu",
      title: "Tags / Rótulos",
    },
  },
  {
    path: "/departamentos",
    name: "Departments",
    component: Departments,
    meta: {
      layout: "menu",
      title: "Departmentos",
    },
  },
  {
    path: "/equipe",
    name: "Equipe",
    component: Equipe,
    meta: {
      layout: "menu",
      title: "Equipe",
    },
  },
  {
    path: "/agendamento",
    name: "MensagensAgendadas",
    component: MensagensAgendadas,
    meta: {
      layout: "menu",
      title: "Mensagens agendadas",
    },
  },
  {
    path: "/audiencias",
    name: "Audiencias",
    component: Audiencias,
    meta: {
      layout: "menu",
      title: "Audiências",
    },
  },
  {
    path: "/campanhas",
    name: "Campanhas",
    component: Campanhas,
    meta: {
      layout: "menu",
      title: "Campanhas",
    },
  },
  {
    path: '/flows',
    name: 'FlowList',
    component: FlowList,
    meta: {
        layout: "menu",
        title: "Flows",
    }
  },
  {
    path: '/flows/:flowId',
    name: 'FlowSingle',
    component: FlowSigle,
    meta: {
        layout: "fullscreen",
        title: "Flow",
    }
  },
  /*
  {
    path: "/preferencias",
    name: "Preferências",
    component: Preferencias,
    meta: {
      layout: "menu",
      title: "Preferências",
    },
  },
  */

  // keep this route as the last one
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/404.vue"),
    meta: {
      layout: "menu",
      title: "Página não encontrada",
    },
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/login", "/cadastro", "/password-recovery"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("token");


  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
